<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Manajemen Akses FPP</h5>
      </template>
      <div
        class="search-wrapper d-flex align-items-center justify-content-between"
      >
        <div>
          <b-button
            class="mb-3 mr-2"
            variant="pgiBtn"
            @click="$bvModal.show('m-create-manage-access-fpp')"
            ><i class="fa fa-plus fs-6"></i> Tambah Data</b-button
          >
        </div>
      </div>

      <!-- Table -->
      <b-table
        bordered
        hover
        show-empty
        :busy="isBusy"
        :items="manageAccessFPP"
        :fields="fields"
        :perPage="perPage"
        :sort-by.sync="sortBy"
        responsive
        class="mb-3"
      >
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner
              small
              class="align-middle"
              variant="secondary"
            ></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span
            >
          </div>
        </template>

        <template v-slot:cell(kategori_proses)="row">
          <template v-if="row.item.kategori_proses == 0">
            <b-badge pill variant="warning">Ditinjau Ulang</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 1">
            <b-badge pill variant="primary">Disetujui 1</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 2">
            <b-badge pill variant="primary">Disetujui 2</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 3">
            <b-badge pill variant="info" class="text-white">Diproses</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 4">
            <b-badge pill variant="success">Pengajuan Selesai</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 8">
            <b-badge pill variant="secondary">Dibatalkan</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 10">
            <b-badge pill variant="dark">Ditolak</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 11">
            <b-badge pill variant="danger">Dijadwalkan Ulang</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 12">
            <b-badge pill variant="success">Petugas Hadir</b-badge>
          </template>

          <template v-else-if="row.item.kategori_proses == 13">
            <b-badge pill variant="success">Menunggu Penilaian</b-badge>
          </template>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button
              size="sm"
              class="mr-1"
              variant="warning"
              v-b-tooltip.hover
              title="Edit"
              name="btnEditKategoriPICFPP"
              @click="
                showModalEdit(
                  `${row.item.id}`,
                  `${row.item.user_id}`,
                  `${row.item.name}`,
                  `${row.item.devisi_id}`,
                  `${row.item.divisi}`,
                  `${row.item.kategori_id}`,
                  `${row.item.kategori_proses}`
                )
              "
            >
              <i class="fa fa-pencil"></i>
            </b-button>

            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              v-b-tooltip.hover
              title="Delete"
              name="btnDeleteKategoriPICFPP"
              @click="showSweetAlertDelete(`${row.item.id}`)"
            >
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div
        class="pagination-wrapper d-flex align-items-center justify-content-between"
      >
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>

        <b-pagination
          class="mb-0"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="onPageChange"
        ></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal
      id="m-create-manage-access-fpp"
      size="md"
      title="Tambah Akses PIC FPP"
      ref="my-modal-kategori-pic-fpp"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <b-form @submit.prevent="onConfirm">
        <b-form-group label="Divisi" label-for="divisi" class="required">
          <multiselect
            placeholder="Pilih Divisi"
            label="text"
            track-by="id"
            v-model="form.id_divisi"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :options="optionsDivision"
            @select="onSelectDivision($event)"
            @remove="onRemove($event)"
          ></multiselect>
          <small
            v-if="!$v.form.id_divisi.required && $v.form.id_divisi.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group
          label="Nama Pengguna"
          label-for="nama-pengguna"
          class="required"
        >
          <multiselect
            placeholder="Pilih Nama Pengguna"
            label="text"
            track-by="id"
            v-model="form.id_user"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :options="optionsUsers"
          ></multiselect>
          <small
            v-if="!$v.form.id_user.required && $v.form.id_user.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group
          label="Jenis Pengajuan"
          label-for="kategori-jenis"
          class="required"
        >
          <b-form-select
            v-model="form.id_kategori_jenis"
            size="md"
            :plain="false"
          >
            <template v-slot:first>
              <b-form-select-option disabled :value="null"
                >Pilih Jenis Pengajuan</b-form-select-option
              >
            </template>

            <b-form-select-option
              v-for="option in optionsCategory"
              v-bind:key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <small
            v-if="
              !$v.form.id_kategori_jenis.required &&
              $v.form.id_kategori_jenis.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group label="Hak Akses" label-for="hak akses" class="required">
          <b-form-select
            v-model="form.kategori_proses"
            size="md"
            :plain="false"
          >
            <template v-slot:first>
              <b-form-select-option disabled :value="[]"
                >Pilih Jenis Akses</b-form-select-option
              >
            </template>

            <b-form-select-option
              v-for="option in optionsStatus"
              v-bind:key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-select-option>
          </b-form-select>
          <small
            v-if="
              !$v.form.kategori_proses.required &&
              $v.form.kategori_proses.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal"
            >Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Edit -->
    <b-modal
      id="m-edit-kategori-pic-fpp"
      class="modal-lg"
      title="Kategori PIC FPP"
      ref="my-modal-kategori-pic-fpp"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
    >
      <b-form @submit.prevent="onConfirmEdit">
        <b-form-group label="Divisi" label-for="divisi" class="required">
          <multiselect
            placeholder="Pilih Divisi"
            label="text"
            track-by="id"
            v-model="formEdit.id_divisi"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :options="optionsDivision"
            @select="onSelectDivision($event)"
            @remove="onRemove($event)"
          ></multiselect>
          <small
            v-if="
              !$v.formEdit.id_divisi.required && $v.formEdit.id_divisi.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group
          label="Nama Pengguna"
          label-for="nama-pengguna"
          class="required"
        >
          <multiselect
            placeholder="Pilih Nama Pengguna"
            label="text"
            track-by="id"
            v-model="formEdit.id_user"
            :show-labels="false"
            :close-on-select="true"
            :multiple="false"
            :options="optionsUsers"
          ></multiselect>
          <small
            v-if="!$v.formEdit.id_user.required && $v.formEdit.id_user.$dirty"
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group
          label="Jenis Pengajuan"
          label-for="kategori-jenis"
          class="required"
        >
          <b-form-select
            v-model="formEdit.id_kategori_jenis"
            size="md"
            :plain="false"
          >
            <template v-slot:first>
              <b-form-select-option disabled :value="null"
                >Pilih Jenis Pengajuan</b-form-select-option
              >
            </template>

            <b-form-select-option
              v-for="option in optionsCategory"
              v-bind:key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </b-form-select-option>
          </b-form-select>
          <small
            v-if="
              !$v.formEdit.id_kategori_jenis.required &&
              $v.formEdit.id_kategori_jenis.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <b-form-group label="Hak Akses" label-for="hak akses" class="required">
          <b-form-select
            v-model="formEdit.kategori_proses"
            size="md"
            :plain="false"
          >
            <template v-slot:first>
              <b-form-select-option disabled :value="null"
                >Pilih Jenis Akses</b-form-select-option
              >
            </template>

            <b-form-select-option
              v-for="option in optionsStatus"
              v-bind:key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-select-option>
          </b-form-select>
          <small
            v-if="
              !$v.formEdit.kategori_proses.required &&
              $v.formEdit.kategori_proses.$dirty
            "
            class="text-danger"
            >Kolom tidak boleh kosong</small
          >
        </b-form-group>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit">Simpan Perubahan</b-button>
          <b-button class="ml-2" variant="danger" @click="hideModal"
            >Batal</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      sortBy: "no",
      filter: {
        name: null,
      },
      form: {
        id_user: null,
        id_divisi: null,
        id_kategori_jenis: null,
        kategori_proses: [],
        length_kategori_proses: 0,
      },
      formEdit: {
        id: null,
        id_user: null,
        id_divisi: null,
        id_kategori_jenis: null,
        kategori_proses: null,
      },
      fields: [
        {
          key: "no",
          label: "No.",
          thClass: "nameOfTheClassThNumber",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "divisi",
          label: "Divisi",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "jenis_pengajuan",
          label: "Jenis Pengajuan",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "kategori_proses",
          label: "Hak Akses",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      optionsStatus: [
        {
          value: 1,
          label: "Disetujui 1",
        },
        {
          value: 2,
          label: "Disetujui 2",
        },
        {
          value: 3,
          label: "Diproses",
        },
      ],
      manageAccessFPP: [],
      dataUsers: [],
      optionsUsers: [],
      optionsDivision: [],
      optionsCategory: [],
      outputUser: "",
      outputDivisi: "",
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      loading: false,
      query: "",
    };
  },
  validations: {
    form: {
      id_user: {
        required,
      },
      id_divisi: {
        required,
      },
      id_kategori_jenis: {
        required,
      },
      kategori_proses: {
        required,
      },
    },
    formEdit: {
      id_user: {
        required,
      },
      id_divisi: {
        required,
      },
      id_kategori_jenis: {
        required,
      },
      kategori_proses: {
        required,
      },
    },
  },

  methods: {
    //----------------- Initial Function -----------------//
    init: function () {
      this.initStatus = 0;
      this.length_kategori_proses = this.form.kategori_proses.length;
      let getManageAccess = "";

      // Sub Kategori FPP
      getManageAccess = this.$axios
        .get("api/internal-memo/kategori-pic")
        .then((response) => {
          let datas = response.data.data;
          let number = 1;

          for (var data of datas.data) {
            this.manageAccessFPP.push({
              no: number++,
              id: data.id,
              user_id: data.user_id,
              devisi_id: data.devisi_id,
              kategori_id: data.kategori.id,
              name: data.user.name,
              divisi: data.devisi.nm_Divisi,
              kategori_proses: data.kategori_proses,
              jenis_pengajuan: data.kategori.name,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
        });

      // Get Divisi
      getManageAccess = this.$axios
        .get("api/admin/devisi/all")
        .then((response) => {
          let datas = response.data.data;

          for (let division of datas) {
            this.optionsDivision.push({
              id: division.DivisiID,
              text: division.nm_Divisi,
            });
          }
        });

      // Get Users
      getManageAccess = this.$axios
        .get("api/admin/management-users/all")
        .then((response) => {
          let datas = response.data.data;

          for (let user of datas) {
            this.dataUsers.push({
              id: user.id,
              text: user.name,
              divisi: user.devisi_id,
            });
          }
        });

      // Get Category
      getManageAccess = this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          let datas = response.data.data;

          for (let data of datas) {
            this.optionsCategory.push({
              id: data.id,
              name: data.name,
            });
          }
        });

      Promise.all([getManageAccess])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange() {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getKategori();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    //-----------------  Callback Function -----------------//
    getManagementAccessFPP: function () {
      this.toggleBusy();

      this.$axios
        .get("api/internal-memo/kategori-pic")
        .then((response) => {
          let datas = response.data.data;
          let number = 1;

          this.manageAccessFPP = [];
          this.toggleBusy();

          for (var data of datas.data) {
            this.manageAccessFPP.push({
              no: number++,
              id: data.id,
              user_id: data.user_id,
              devisi_id: data.devisi_id,
              kategori_id: data.kategori.id,
              name: data.user.name,
              divisi: data.devisi.nm_Divisi,
              kategori_proses: data.kategori_proses,
              jenis_pengajuan: data.kategori.name,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    // Function Select Division For Get Data Users
    onSelectDivision: function (e) {
      this.optionsUsers = [];

      for (let user of this.dataUsers) {
        if (user.divisi == e.id) {
          this.optionsUsers.push({
            id: user.id,
            text: user.text,
          });
        }
      }
    },

    // Function Unselect Division For Remove Data Users
    onRemove: function (e) {
      if (e) {
        this.optionsUsers = [];
      }
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        id_user: null,
        id_divisi: null,
        id_kategori_jenis: null,
        kategori_proses: [],
        length_kategori_proses: 0,
      };

      this.formEdit = {
        id_user: null,
        id_divisi: null,
        id_kategori_jenis: null,
        kategori_proses: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal-kategori-pic-fpp"].hide();
      this.resetForm();
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-manage-access-fpp");
      }
    },

    showSweetAlertCreate: function () {
      let katProses = [];
      katProses.push(this.form.kategori_proses);

      let form = {
        user_id: this.form.id_user.id,
        devisi_id: this.form.id_divisi.id,
        id_kategori_jenis_fpp: this.form.id_kategori_jenis,
        kategori_proses: katProses,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        title: "Konfirmasi",
        html: "<strong>Konfirmasi,</strong><span> untuk melanjutkan penyimpanan</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon menunggu &#128521",
            html: "Penyimpanan data sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post("/api/internal-memo/kategori-pic", form)
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  icon: "success",
                  html: "Selamat! Data berhasil disimpan &#128516",
                });
              }
              this.resetForm();
              this.getManagementAccessFPP();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Data gagal disimpan &#128532",
              });
            });
        } else {
          this.resetForm();
          this.getManagementAccessFPP();
        }
      });
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-kategori-pic-fpp");
      }
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (
      id,
      user_id,
      name,
      devisi_id,
      divisi,
      kategori_id,
      kategori_proses
    ) {
      this.formEdit.id = id;
      this.formEdit.id_user = {
        id: user_id,
        text: name,
      };
      this.formEdit.id_divisi = {
        id: devisi_id,
        text: divisi,
      };
      this.formEdit.id_kategori_jenis = kategori_id;
      this.formEdit.kategori_proses = kategori_proses;
      this.$bvModal.show("m-edit-kategori-pic-fpp");
    },

    showSweetAlertEdit: function () {
      let formEdit = {
        id: this.formEdit.id,
        user_id: this.formEdit.id_user.id,
        devisi_id: this.formEdit.id_divisi.id,
        id_kategori_jenis_fpp: this.formEdit.id_kategori_jenis,
        kategori_proses: this.formEdit.kategori_proses,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "<strong>Konfirmasi,</strong><span> untuk melanjutkan perubahan data.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon menunggu &#128521",
            html: "Penyimpanan data sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post(
              "/api/internal-memo/kategori-pic/" + this.formEdit.id,
              formEdit
            )
            .then((response) => {
              this.$bvModal.hide("m-edit-kategori-pic-fpp");

              if (response.status == 200) {
                Toast.fire({
                  icon: "success",
                  html: "Selamat! Data berhasil diubah &#128516",
                });
              }

              this.resetForm();
              this.getManagementAccessFPP();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Data gagal diubah &#128532",
              });
            });
        } else {
          this.resetForm();
          this.getManagementAccessFPP();
        }
      });
    },

    showSweetAlertDelete: function (id) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "<strong>Konfirmasi,</strong><span> apakah anda yakin menghapus data ini?</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon menunggu &#128521",
            html: "Data sedang dihapus...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .delete("/api/internal-memo/kategori-pic/delete/" + id)
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  icon: "success",
                  html: "Selamat! Data berhasil dihapus &#128516",
                });
              }
              this.resetForm();
              this.getManagementAccessFPP();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Data gagal dihapus &#128532",
              });
            });
        } else {
          this.resetForm();
          this.getManagementAccessFPP();
        }
      });
    },
  },

  //-----------------  Initial Function -----------------//
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: #f79483;
}

.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThNumber {
  width: 5%;
  text-align: center;
}

.nameOfTheClassThAction {
  width: 10%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}
</style>
